/* eslint-disable @typescript-eslint/no-unused-vars */
// const protocol = window.location.protocol;

const HOST_SERVER = process.env.REACT_APP_SERVER;

const DEV = "http://dev.backend.pattalamgroup.com/public";
const DEV_API = "http://dev.backend.pattalamgroup.com/public/api";

const PROD = "https://pattalamgroup.com/public";
const PROD_API = "https://pattalamgroup.com/public/api";

const NEW = "http://165.22.214.176:8080/public";
const NEW_API = "http://165.22.214.176:8080/public/api";

const STAGE = "http://stage.admin.pattalamgroup.com";
const STAGE_API = "http://stage.admin.pattalamgroup.com/public/api";

const LOCAL = "http://192.168.1.6/grameenmilk";
const LOCAL_API = "http://192.168.1.6/grameenmilk/public/api";

const HOST: string = HOST_SERVER === "prod" ? PROD : DEV;

const HOST_API: string = HOST_SERVER === "prod" ? PROD_API : DEV_API;

const API = {
  HOST: HOST,
  HOST_API: HOST_API,

  //Authentication
  LOGIN: `${HOST_API}/login`,
  REFRESH_TOKEN: `${HOST_API}/refreshtoken`,
  CHANGE_PASSWORD: `${HOST_API}/changePassword`,
  FORGOT_PASSWORD: `${HOST_API}/auth/forgot-password`,
  RESET_PASSWORD: `${HOST_API}/auth/reset-password`,
  VALIDATE_RESET_PASSWORD: `${HOST_API}/auth/reset-password/validate`,
  LOGOUT: `${HOST_API}/logout`,

  //Routes
  ROUTE_LIST: `${HOST_API}/routeListData`,
  ADD_ROUTE: `${HOST_API}/addRoute`,
  VIEW_ROUTE: `${HOST_API}/viewRoute`,
  UPDATE_ROUTE: `${HOST_API}/updateRoute`,
  DELETE_ROUTE: `${HOST_API}/deleteRoute`,
  RECIEVE_PETTY_CASH: `${HOST_API}/receivePettyCash`,

  //Customers
  CUSTOMER_LIST: `${HOST_API}/customerList`,
  CUSTOMER_VIEW: `${HOST_API}/viewCustomer`,
  ADD_CUSTOMER: `${HOST_API}/addCustomer`,
  UPDATE_CUSTOMER: `${HOST_API}/updateCustomer`,
  DELETE_CUSTOMER: `${HOST_API}/deleteCustomer`,
  UPDATE_CUSTOMER_STATUS: `${HOST_API}/updateCustomerStatus`,
  SUBMIT_INDIVIDUAL_PAYMENT: `${HOST_API}/submitAdminPayment`,
  CUSTOMER_PAYMENT_DETAILS: `${HOST_API}/customerPaymentDetails`,
  DELETE_PAYMENT: `${HOST_API}/deletePayment`,

  //Orders
  ORDER_LIST: `${HOST_API}/orderList`,
  ADD_ORDER: `${HOST_API}/addOrder`,
  UPDATE_ORDER: `${HOST_API}/updateOrder`,
  DELETE_ORDER: `${HOST_API}/deleteOrder`,

  //Products
  PRODUCT_LIST: `${HOST_API}/productList`,
  VIEW_PRODUCT: `${HOST_API}/viewProduct`,
  ADD_PRODUCT: `${HOST_API}/addProduct`,
  UPDATE_PRODUCT: `${HOST_API}/updateProduct`,
  DELETE_PRODUCT: `${HOST_API}/deleteProduct`,

  //Dropdown Values
  ALL_ROUTES: `${HOST_API}/routeList`,
  ROUTE_CUSTOMERS: `${HOST_API}/routeCustomers`,
  ALL_PRODUCTS: `${HOST_API}/productListData`,

  //Users
  USER_LIST: `${HOST_API}/userList`,
  USER_CREATE: `${HOST_API}/addUser`,
  USER_VIEW: `${HOST_API}/viewUser`,
  USER_UPDATE: `${HOST_API}/updateUser`,
  USER_DELETE: `${HOST_API}/deleteUser`,

  //Misc
  ALL_USERS: `${HOST_API}/getAllUsers`,

  //Route Admins
  ROUTE_ADMIN_LIST: `${HOST_API}/routeManagersList`,
  ROUTE_ADMIN_CREATE: `${HOST_API}/addRouteEmployee`,
  ROUTE_ADMIN_VIEW: `${HOST_API}/employeeRouteList`,
  ROUTE_ADMIN_UPDATE: `${HOST_API}/updateRouteEmployee`,
  ROUTE_ADMIN_DELETE: `${HOST_API}/deleteRouteEmployee`,
  ROUTE_ADMINS: `${HOST_API}/routeManagers`,

  //Reports
  COLLECTION_REPORT: `${HOST_API}/routeCollection`,
  CUSTOMER_REPORT: `${HOST_API}/salesReport`,
  CUSTOMER_SALES_REPORT: `${HOST_API}/supplyDetails`,
  ROUTE_PRODUCT_SUMMARY: `${HOST_API}/routeSupplyDetails`,
  ALL_ROUTE_PRODUCT_SUMMARY: `${HOST_API}/allSupplyDetails`,
  ITEM_CONSOLIDATED_REPORT: `${HOST_API}/itemConsolidatedReport`,
  ROUTE_SALES_REPORT: `${HOST_API}/routeSalesConsolidateReport`,
  ITEM_CONSOLIDATED_REPORT_PDF: `${HOST_API}/itemConsolidatedReportPdf`,
  ROUTE_SALES_REPORT_PDF: `${HOST_API}/routeSalesConsolidateReportPdf`,
  PETTY_ROUTE_USERS: `${HOST_API}/getPettyRouteUsersList`,
  PETTY_ADMINS: `${HOST_API}/getPettyCollectedUsersList`,
  PEETY_CASH_REPORT: `${HOST_API}/pettyCashReport`,
  DELETE_PETTY_CASH_COLLECTION: `${HOST_API}/deletePettyCashDetails`,
  ROUTE_ADMIN_SALES_SUMMARY_REPORT: `${HOST_API}/routeAdminSalesSummaryReport`,
  ROUTE_ADMIN_SALES_SUMMARY_REPORT_PDF: `${HOST_API}/routeAdminSalesSummaryReportPdf`,
  ROUTE_ADMIN_SALES_SUMMARY_DETAILS: `${HOST_API}/routeAdminSalesSummaryDetails`,
  ROUTE_ADMIN_SALES_SUMMARY_DETAILS_PDF: `${HOST_API}/routeAdminSalesSummaryDetailsPdf`,
  ROUTE_ADMIN_SALES_CUSTOMER_PRODUCTWISE_DETAILS: `${HOST_API}/routeAdminSalesCustomerProductWiseReport`,
  ROUTE_ADMIN_SALES_CUSTOMER_PRODUCTWISE_DETAILS_PDF: `${HOST_API}/routeAdminSalesCustomerProductWiseReportPdf`,

  //Route Admin Routes
  ROUTE_MANAGER_ROUTES_LIST: `${HOST_API}/routeManagerRoutes`,
  ROUTE_MANAGER_ROUTE_VIEW: `${HOST_API}/viewManagerRoute`,
  ROUTE_MANAGER_PETTY_COLLECT: `${HOST_API}/managerPettyCashReceive`,
  ROUTE_MANAGER_CUSROMER_LIST: `${HOST_API}/customerListForManager`,
  ROUTE_MANAGER_ROUTES: `${HOST_API}/routeManagerRoutesList`,
  ROUTE_MANAGER_CUSTOMER_REPORT: `${HOST_API}/salesReportForManagers`,
  ROUTE_MANAGER_CUSTOMER_SALES_REPORT: `${HOST_API}/supplyDetailsForManager`,
  ROUTE_MANAGER_SUBMIT_INDIVIDUAL_PAYMENT: `${HOST_API}/submitManagerPayment`,
  ROUTE_MANAGER_CUSTOMER_PAYMENT_DETAILS: `${HOST_API}/customerPaymentDetailsForManager`,
  ROUTE_MANAGER_CUSTOMER_VIEW: `${HOST_API}/viewCustomerForManager`,
  ROUTE_MANAGER_COLLECTION_REPORT: `${HOST_API}/routeCollectionForManager`,
  ROUTE_MANAGER_LIST_FOR_ROUTE_MANAGER: `${HOST_API}/routeManagersListForManager`,
  ROUTE_MANAGER_DETAILS_FOR_ROUTE_MANAGER: `${HOST_API}/routeManagerDetailsForManager`,
  ROUTE_MANAGER_COLLECT_PETTY_FOR_ROUTE_MANAGER: `${HOST_API}/receivePettyCashForManager`
};

export default API;
